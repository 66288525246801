.markdownText{
  text-align: left;
  margin: 0 55px;
  font-size: calc(4px + 2vmin);
  letter-spacing: -.065em
}

.contentBackgroundColumn{
    display:inline-flex;
  background-color: rgba(0, 0, 0,.25);
  flex-direction: column;
  align-self: center;
  justify-content: top;
  align-items: center;
  overflow-y: visible;
  position: center;
  margin-top: 10px;
  width: 800px;
  height: min-content;
  /* flex-basis: inherit; */
  text-align: left;
  
  border-radius: 10px;
}

.contentBackgroundColumnQR{
  display:inline-flex;
background-color: rgb(255, 255, 255);
flex-direction: column;
align-self: center;
justify-content: top;
align-items: center;
overflow-y: visible;
position: center;
margin-top: 10px;
width: 1150px;
height: min-content;
/* flex-basis: inherit; */
text-align: left;

border-radius: 10px;
}

img[alt=photo] { width: 65vw; text-align: center; max-width: 600px; }
img[alt=photo-small] { width: 65vw; text-align: center; max-width: 300px; }

.contentflexWrapper{
  position: relative;
  top:40vh;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
 
}


@media screen and (max-width: 1200px) {
    .contentBackgroundColumn{
      width: 80vw;
    }
    .contentBackgroundColumnQR{
      width: 80vw;
    }
    .headerImg{
      width: 90vw;
    }
  }

.headerImg{
  max-width: 1000px;
  max-height: 60vh;
  margin-top: 120px;
  position: relative;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.content{
  position: absolute;
  top: 0px;
  left:0px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(135deg, #1db35b, #2246be);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
  width: 100%;
  flex-basis: fit-content;
  /* padding-top: 35vh; */
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}

}
.QR{
  padding-top: 150px;
  padding-bottom: 50px;
  width: 60%;
}
.QRInput{
  width: 80%;
  height: 30px;
  border-radius: 10px;
  border: 1px solid black;
  margin-top: 10px;
  font-size: .75em;
  text-align: left;
  margin-bottom: 10px;
}