.App {
  text-align: center;
  background-color: #282c34;
  overflow-y: visible;
  font-family: "Inter";

  /* max-height: 100vh; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #091121;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: visible;
  background: linear-gradient(135deg, #1db35b, #2246be);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}
@keyframes gradient {
	0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}
.window{
  width:100vw;
  height:100vh;
  overflow: visible;
}

.topBanner{
  /* margin-top: 300px; */  
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15em;
  padding-top: 40px;
  stroke: 5px;
  width: 100vw;
  /* overflow-y: visible; */
  background-color:rgba(27, 21, 58, 0.514);
  border-bottom: 1px solid #e5e5e5;
}
.contentWrapper{
  display: inline-block;
}
.contentBackground{
  display:inline-flex;
  background-color: rgba(0, 0, 0,.25);
  flex-direction: column;
  align-self: center;
  justify-content: top;
  align-items: center;
  overflow-y: auto;
  position: center;
  margin-top: 50px;
  margin-bottom: 120px;
  min-width: 650px;
  height: min-content;
  /* flex-basis: inherit; */
  
  
  border-radius: 10px;
}
@media screen and (max-width: 800px) {
  .contentBackground{
    min-width: 90vw;
  }
  
}
  

.headerBar{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  justify-content:right;
  align-items: center;
  width: 90vw;
  max-width: 100%;
  height: 40px;
  
  color: white;
  padding: 0 5vw;
  height: 100px;
}
.headerBarText{
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  width: 90vw;
  max-width: 100%;
  height: 40px;
  color: white;
  padding: 0 5vw;
  height: 100px;
  flex-grow:1;
}

.headerBarLink{
  text-align: right;
  color:white;
  text-decoration: none;

}
.headerBarText2{
  user-select: none;
}
.headerBarText2:hover{
  cursor: pointer;
}





.colorBar{
  background-color: #3c90a7;
  /* width: 100%; */
  /* height: 10px; */
}
.nameBennett {
  color: white;
  font-size: 3em;
  line-height: .9em;
  font-weight: 700;
  font-kerning: 2em;
  text-transform: uppercase;
  font-family: "Consolas", "Inter";
  transform: translateX(-120px)
              rotate(0deg)
              skew(-20deg);
  margin: 0;
  padding: 0;
  
}
.nameHermanoff {
  color: white;
  font-size: 3em;
  line-height: .9em;
  font-weight: 700;
  font-kerning: 2em;
  text-transform: uppercase;
  font-family: "Consolas", "Inter";
  transform: translateX(60px)
              rotate(0deg)
              skew(-20deg);
  margin: 0;
  padding: 0;
  
}
@media screen and (max-width: 300){
  .nameHermanoff{
    font-size: 1em;
  }
}
.textMoveTop {
  position: relative;
  
  left:0px;
  animation: moveTop 2s ease-in-out infinite;
}
.textMoveBottom {
  position: relative;
  left:00px;
  animation: moveBottom 2s ease-in-out infinite;
}
.nameBreakLineTop {
  border-top: 2.5px solid white;
  border-bottom: none;
  width: 400vw;
  margin: 0;
  /* border-top: '3000px solid red';
  margin-top: '-3000px', */
  /* transform: rotate(-15deg); */
              
}

.nameBreakLineBottom {
  border-top: 2.5px solid white;
  border-bottom: none;
  width: 400vw;
  margin: 0;
  /* transform: rotate(-15deg); */
              
}
.loadscreen{
  position: absolute;
  overflow: hidden;
  height: 100vh;
  /* padding-top: 60vh; */
  width: 100vw;
  pointer-events: none;
  
}
.loadscreenWrapper{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
