

.contentbox-testimg::before{
    content: "";
   position: absolute;
   top: 0; left: 0;
  width: 100%; height: 100%;
    background-image: url('./../testimg.png');

    filter: blur(5px);
    
    
}
.contentbox-testimg{
    position: relative;
    width: 95%;
    border: 2px solid #ffffff;
    border-radius: 5px;
    margin: 5px;
    flex: 1 1 auto;
   /* margin-left: 60px; */
}
.contentboxtext{
    position: relative;
}